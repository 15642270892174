<template>
    <div class="home-box">
      <div>
        <div class="el-dialog-header-box">
          <div class="dialog-title-block"></div>
          <span class="dialog-title-text">商家管理</span>
        </div>
        <el-divider />
      </div>

      <!-- 查询区域 -->
      <div class="top-query">
        <div class="top-query-left">
          <div v-if="!queryType" class="demo-input-suffix">
            商家状态：
            <el-select v-model="query.isshenhe" filterable clearable placeholder="请选择商家状态">
              <el-option
                v-for="item in isshenheOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="demo-input-suffix">
            商家名称：
            <el-select v-model="query.storename" filterable clearable placeholder="请输入商家名称">
              <el-option
                v-for="item in storeOptions"
                :key="item.id"
                :label="item.storename"
                :value="item.storename">
              </el-option>
            </el-select>
          </div>
          <div class="demo-input-suffix">
            商家Id：
            <el-input
              placeholder="请输入商家Id"
              prefix-icon="el-icon-search"
              v-model="query.storeid"
              clearable
            ></el-input>
          </div>
          <div class="demo-input-suffix">
            手机号：
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-search"
              type="number"
              class="inputFund"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="query.mobile"
              clearable
            ></el-input>
          </div>
          <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          <el-button @click="add" type="success" icon="el-icon-plus">新增</el-button>
        </div>

        <div class="top-query-right"></div>
      </div>

      <!-- table区域-begin -->
      <div class="table-box">
        <el-table :data="tableData.data" stripe style="width: 100%" v-loading="loading">
          <el-table-column prop="id" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="name" label="名称" min-width="280">
            <template slot-scope="scope">
              <el-tag v-if="queryType==2&&scope.row.is_ziti==1" effect="dark" size="small" type="success">支持自动提现</el-tag><br>
              <span>{{ scope.row.storename }}</span><br>
              <!-- <span>商家级别：{{ scope.row.total_balance }}</span><br> -->
              <span>执照名称：{{ scope.row.yyzz_name }}</span><br>
              <span>account_id：{{ scope.row.account_id }}</span><br>
              <span>代理ID：{{ scope.row.agentid }}</span><br>
              <span v-if="queryType==3">拒绝原因：
                <!-- <el-tooltip effect="dark" :content="scope.row.shenhe_msg" placement="bottom-start"> -->
                  <el-tag type="danger">{{ scope.row.shenhe_msg }}</el-tag>
                <!-- </el-tooltip> -->
              </span>
              <span v-if="queryType==1">审核备注：
                <!-- <el-tooltip effect="dark" :content="scope.row.shenhe_msg" placement="bottom-start"> -->
                  <el-tag>{{ scope.row.shenhe_msg }}</el-tag>
                <!-- </el-tooltip> -->
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="withdraw_code" label="提现账号" min-width="240">
            <template slot-scope="scope">
              <span style="font-size: 17px;">银行收款记账户信息</span><br>
              <el-tag type="warning" style="height:auto;color: #000;">
                <span style="white-space: normal;height:auto;">{{ scope.row.bank_info }}</span><br>
                <span>提现账号：{{ scope.row.withdraw_code }}</span><br>
                <span>系统账号：{{ scope.row.login_code }}</span><br>
                <span>系统密码：{{ scope.row.password }}</span><br>
              </el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="logo" label="Logo" min-width="120">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px" :src="scope.row.logo" fit="fit"></el-image>
            </template>
          </el-table-column> -->
          <el-table-column prop="mobile" label="商户联系人" min-width="120">
          </el-table-column>
          <el-table-column prop="name" label="提现分账" min-width="220">
            <template slot-scope="scope">
              <span>执行日期：{{ scope.row.withdraw_date }}</span><br>
              <span>执行时间：{{ scope.row.withdraw_time }}</span><br>
              <span>执行状态：{{ scope.row.withdraw_code }}</span><br>
              <span>备注：{{ scope.row.withdraw_msg }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="agentid" label="业务员ID" min-width="120">
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="80">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 1 ? '' : 'danger'">{{ scope.row.status == 1 ? '显示' : '禁用' }}</el-tag>
            </template>>
          </el-table-column>
          <el-table-column prop="createdate" label="添加时间" min-width="120">
          </el-table-column>
          <!-- <el-table-column prop="createdate" label="所属业务员" min-width="120">
          </el-table-column> -->
          <el-table-column fixed="right" header-align="center" align="center" label="操作" min-width="380">
            <template slot-scope="scope">
              <el-button style="margin-top:10px" v-if="queryType!=1" type="primary" plain size="small" @click="handleEdit(scope.row.id)">编辑</el-button>
              <el-button style="margin-top:10px" v-if="user.uniacid==12" type="primary" plain size="small" @click="mchntUpd(scope.row.id)">富友信息登记</el-button>
              <el-button style="margin-top:10px" v-if="user.uniacid==12" type="primary" plain size="small" @click="purchaseGood(scope.row.id)">进件</el-button>
              <el-button style="margin-top:10px" v-if="user.uniacid==12" type="primary" plain size="small" @click="mchntStatusQuery(scope.row.id)">富友开通查询</el-button>
              <el-popconfirm v-if="scope.row.agentid" title="确定解绑业务员吗" @confirm="unbindSalesman(scope.row.id)">
                <el-button style="margin-top:10px"  slot="reference" type="danger" plain size="small">解绑业务员</el-button>
              </el-popconfirm>
              <el-button v-else style="margin-top:10px" slot="reference" type="success" plain size="small" @click="bindAgent(scope.row.id)">绑定业务员</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- table区域-end -->

      <!-- 分页区域-begin -->
      <div>
        <el-pagination
          class="pagination"
          background layout="prev, pager, next"
          :total="tableData.total"
          :page-size="tableData.per_page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- 分页区域-end -->

      <!-- dialog -->
      <el-dialog :title="title" :width="dialogWidth" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="dialogFormClose('form')">
        <el-form v-if="dialogType == 'add' || dialogType == 'edit'" :model="form" :rules="rules" :label-width="formLabelWidth" ref="form">
          <el-row>
            <el-col :span="12">
              <el-form-item label="门店名称" prop="storename">
                <el-input v-model="form.storename" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="抖音商户ID" prop="account_id">
                <el-input v-model="form.account_id" style="width: 90%" :disabled="dialogType == 'edit' && queryType==2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="入住平台" prop="platform">
                <el-select v-model="form.platform" multiple filterable clearable placeholder="请选择" style="width: 90%">
                  <el-option
                    v-for="item in platformOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="店铺成立时间" prop="cl_date">
                <el-date-picker v-model="form.cl_date" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="平台店铺链接" prop="link_url">
                <el-input v-model="form.link_url" placeholder="店铺链接" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="日均流水" prop="day_money">
                <el-input v-model="form.day_money" placeholder="例如：日均流水5万左右" style="width: 90%" ></el-input>
                <span style="display: block;color: #606266;height: 26px;">例如：日均流水5万左右</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品价格范围" prop="jg_price">
                <el-input v-model="form.jg_price" placeholder="例如：49.9元" style="width: 90%"></el-input>
                <span style="display: block;color: #606266;height: 26px;">例如：49.9元</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总销售额" prop="sale_money">
                <el-input v-model="form.sale_money" placeholder="总销售额" style="width: 90%" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="商家实际经营地址" prop="address">
                <el-input v-model="form.address" placeholder="商家实际经营地址" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="主营业务" prop="yewu">
                <el-input v-model="form.yewu" placeholder="例如：公司销售染发类产品" type="textarea" :rows="2" style="width: 90%" ></el-input>
                <span style="display: block;color: #606266;height: 26px;">例如：公司销售染发类产品</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="LOGO" prop="logo">
            <el-upload
              :disabled="dialogType == 'edit' && queryType==2"
              class="upload-demo"
              action="#"
              ref="upload"
              accept="image/*"
              :on-preview="handlePreview"
              :on-remove="function(file, fileList) {return handleRemove(file, fileList,'logo')}"
              multiple
              list-type="picture-card"
              :limit="1"
              :file-list="fileList.logo"
              :http-request="function(file) {return handleFileUpload(file,'logo')}"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
            </el-upload>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="商家联系人电话" prop="mobile">
                <el-input v-model="form.mobile" placeholder="用于接受短信" autocomplete="off" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="法人手机号" prop="faren_mobile">
                <el-input v-model="form.faren_mobile" placeholder="法人手机号" autocomplete="off" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="地区" prop="area">
                <el-cascader v-if="dialogFormVisible" :options="options" v-model="form.area" clearable placeholder="地区" @change="areaChange" :disabled="dialogType == 'edit' && queryType==2"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="status">
                <el-radio-group v-model="form.status" style="width: 90%" :disabled="dialogType == 'edit' && queryType==2">
                  <el-radio :label="1">显示</el-radio>
                  <el-radio :label="2">禁用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="提现银行卡号" prop="withdraw_code">
                <el-input disabled v-model="form.withdraw_code" placeholder="提现银行卡号" style="width: 90%"></el-input>
                <span style="display: block;color: #ff1d1d;height: 26px;">请联系服务商{{dialogType=='add'?'添加':'修改'}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商家原提现银行卡开户行" prop="tx_code">
                <el-input v-model="form.tx_code" placeholder="商家原提现银行卡开户行" type="textarea" :rows="2" style="width: 90%" :disabled="dialogType == 'edit' && queryType==2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="商家抽佣比例" prop="rate">
                <el-input v-model="form.rate" placeholder="商家抽佣比例" :disabled="dialogType == 'edit'||queryType==2" style="width: 90%">
                  <template slot="append">%</template>
                </el-input>
                <span v-if="dialogType == 'edit'" style="display: block;color: #ff1d1d;height: 26px;">修改商家抽佣比例请联系平台客服</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合伙人佣金比例" prop="agent_rate">
                <el-input v-model="form.agent_rate" placeholder="合伙人佣金比例" style="width: 90%">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="推广员佣金比例" prop="tgy_rate">
                <el-input v-model="form.tgy_rate" placeholder="推广员佣金比例" style="width: 90%">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="营业执照名称" prop="yyzz_name">
                <el-input v-model="form.yyzz_name" placeholder="请输入营业执照名称" style="width: 90%" :disabled="dialogType == 'edit' && queryType==2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="商家资质：" prop="card_url">
            <el-upload
              :disabled="dialogType == 'edit' && queryType==2"
              class="upload-demo"
              action="#"
              ref="upload"
              accept="image/*"
              :on-preview="handlePreview"
              :on-remove="(file, fileList)=>{return handleRemove(file, fileList,'card_url')}"
              multiple
              list-type="picture-card"
              :file-list="fileList.card_url"
              :http-request="(file)=>{return handleFileUpload(file,'card_url')}"
              >
              <i class="el-icon-upload"></i>
              <div slot="tip" class="el-upload__tip">营业执照、身份证正反面、法人银行卡正反面(个体工商执照)、对公开户证明(有限公司需提供)</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="店铺资质：" prop="dianpuzizhi">
            <el-upload
              :disabled="dialogType == 'edit' && queryType==2"
              class="upload-demo"
              action="#"
              ref="upload"
              accept="image/*"
              :on-preview="handlePreview"
              :on-remove="(file, fileList)=>{return handleRemove(file, fileList,'dianpuzizhi')}"
              multiple
              list-type="picture-card"
              :file-list="fileList.dianpuzizhi"
              :http-request="(file)=>{return handleFileUpload(file,'dianpuzizhi')}"
              >
              <i class="el-icon-upload"></i>
              <div slot="tip" class="el-upload__tip">入住基本页截图、入住执照预览页截图、订单列表页截图</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="门店资料：" prop="lincese">
            <el-upload
              :disabled="dialogType == 'edit' && queryType==2"
              class="upload-demo"
              action="#"
              ref="upload"
              accept="image/*"
              :on-preview="handlePreview"
              :on-remove="(file, fileList)=>{return handleRemove(file, fileList,'lincese')}"
              multiple
              list-type="picture-card"
              :file-list="fileList.lincese"
              :http-request="(file)=>{return handleFileUpload(file,'lincese')}"
              >
              <i class="el-icon-upload"></i>
              <div slot="tip" class="el-upload__tip">门头照、店内照1、店内照2</div>
            </el-upload>
          </el-form-item>
        </el-form>

        <el-form v-if="dialogType == 'bindAgent'" :model="form" :rules="rules" :label-width="formLabelWidth" ref="form" >
          <el-form-item label="业务员ID" prop="agentid">
            <el-input v-model="form.agentid" style="width: 90%"></el-input>
          </el-form-item>
        </el-form>

        <el-form v-if="dialogType == 'mchntAdd' || dialogType == 'mchntUpd'" :model="fyform" :rules="fyrules" :label-width="formLabelWidth" ref="form">
          <el-row>
            <el-col :span="8">
              <el-form-item label="机构号" prop="ins_cd">
                <el-input v-model="fyform.ins_cd" placeholder="机构号" style="width: 90%" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商户类别" prop="license_type">
                <el-select v-model="fyform.license_type" placeholder="商户类别" style="width: 90%">
                  <el-option key="0" label="企业" :value="0"></el-option>
                  <el-option key="1" label="个体工商户" :value="1"></el-option>
                  <el-option key="2" label="事业单位" :value="2"></el-option>
                  <el-option key="6" label="民办非企业单位" :value="6"></el-option>
                  <el-option key="9" label="其他" value="9"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="门店名称" prop="storename">
                <el-input v-model="fyform.storename" placeholder="门店名称" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="企业名称" prop="real_name">
                <el-input v-model="fyform.real_name" placeholder="企业名称" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商户简称" prop="mchnt_shortname">
                <el-input v-model="fyform.mchnt_shortname" placeholder="商户简称" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="品牌名称" prop="plat_name_cn">
                <el-input v-model="fyform.plat_name_cn" placeholder="品牌名称" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="商户类别码" prop="mchnt_mcc">
                <el-select v-model="fyform.mchnt_mcc" placeholder="商户类别码" style="width: 90%">
                    <el-option v-for="item in mccOptions" :key="item.mcc" :label="item.remark" :value="item.mcc"></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地区" prop="city_cd">
                <el-cascader v-if="dialogFormVisible" :props="fuyouprops" v-model="fyform.area" clearable placeholder="地区" @change="areaChange"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系地址" prop="contact_addr">
                <el-input v-model="fyform.contact_addr" placeholder="联系地址" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="注册地址" prop="lic_regis_addr">
                <el-input v-model="fyform.lic_regis_addr" placeholder="注册地址" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="注册资金" prop="regis_capital">
                <el-input v-model="fyform.regis_capital" placeholder="注册资金" style="width: 90%">
                  <template slot="append">万元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商户联系人" prop="contact_person">
                <el-input v-model="fyform.contact_person" placeholder="商户联系人" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="商户联系人身份证号码" prop="contact_cert_no">
                <el-input v-model="fyform.contact_cert_no" placeholder="商户联系人身份证号码" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="商户联系人身份证开始日" prop="contact_cert_start_dt">
                <el-date-picker v-model="fyform.contact_cert_start_dt" type="date" format="yyyy-MM-dd" value-format="yyyyMMdd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="商户联系人身份证到期日" prop="contact_cert_expire_dt">
                <el-date-picker v-model="fyform.contact_cert_expire_dt" type="date" format="yyyy-MM-dd" value-format="yyyyMMdd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="商户联系人手机" prop="contact_mobile">
                <el-input v-model="fyform.contact_mobile" placeholder="商户联系人手机" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商户联系人邮箱" prop="contact_email">
                <el-input v-model="fyform.contact_email" placeholder="商户联系人邮箱" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业执照证件类型" prop="license_certif_tp">
                <el-radio-group v-model="fyform.license_certif_tp" placeholder="企业执照证件类型" style="width: 90%">
                  <el-radio :label="0">营业执照</el-radio>
                  <el-radio :label="1">营业执照（三证合一）</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="营业执照编号" prop="license_no">
                <el-input v-model="fyform.license_no" placeholder="营业执照编号" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="营业执照有效期起始日" prop="license_start_dt">
                <el-date-picker v-model="fyform.license_start_dt" type="date" format="yyyy-MM-dd" value-format="yyyyMMdd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="营业执照有效期到期日" prop="license_expire_dt">
                <el-date-picker v-model="fyform.license_expire_dt" type="date" format="yyyy-MM-dd" value-format="yyyyMMdd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="法人姓名" prop="artif_nm">
                <el-input v-model="fyform.artif_nm" placeholder="法人姓名" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人手机号" prop="artif_mobile">
                <el-input v-model="fyform.artif_mobile" placeholder="法人手机号" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人证件类型" prop="artif_certif_tp">
                <el-select v-model="fyform.artif_certif_tp" placeholder="法人证件类型" style="width: 90%">
                  <el-option key="0" label="身份证" :value="0"></el-option>
                  <el-option key="1" label="护照" :value="1"></el-option>
                  <el-option key="2" label="通行证" :value="2"></el-option>
                  <el-option key="3" label="警察证" :value="3"></el-option>
                  <el-option key="4" label="台胞证" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="法人证件号" prop="certif_id">
                <el-input v-model="fyform.certif_id" placeholder="法人证件号" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人身份证有效期起始日" prop="card_start_dt">
                <el-date-picker v-model="fyform.card_start_dt" type="date" format="yyyy-MM-dd" value-format="yyyyMMdd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人身份证有效期到期日" prop="certif_id_expire_dt">
                <el-date-picker v-model="fyform.certif_id_expire_dt" type="date" format="yyyy-MM-dd" value-format="yyyyMMdd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="结算方式" prop="acnt_type">
                <el-select v-model="fyform.acnt_type" placeholder="受益人证件类型" style="width: 90%">
                  <el-option key="01" label="对公清算" value="01"></el-option>
                  <el-option key="02" label="对私清算" value="02"></el-option>
                  <el-option key="04" label="双账户清算" value="04"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="对公结算卡号" prop="acnt_no_g">
                <el-input v-model="fyform.acnt_no_g" placeholder="对公结算卡号" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="对公结算卡号联行号" prop="inter_bank_no_g">
                <div style="display: flex;">
                <el-cascader v-if="dialogFormVisible" :props="fuyouprops2" v-model="fyform.inter_dg_area" clearable placeholder="请选择城市" @change="bankareaChange2"></el-cascader>
                <el-select v-model="fyform.inter_bank_no_g" filterable placeholder="对公结算卡号联行号" style="width: 50%">
                  <el-option v-for="item in dgOptions" :key="item.bank_lhh" :label="item.name" :value="item.bank_lhh"></el-option>
                </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="组织机构代码证编号" prop="zzjgzdm_no">
                <el-input v-model="fyform.zzjgzdm_no" placeholder="组织机构代码证编号" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="税务登记证编号" prop="tax_no">
                <el-input v-model="fyform.tax_no" placeholder="税务登记证编号" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="对私结算卡卡号" prop="acnt_no_s">
                <el-input v-model="fyform.acnt_no_s" placeholder="对私结算卡卡号" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="对私结算卡联行号" prop="inter_bank_no_s">
                <div style="display: flex;">
                <el-cascader v-if="dialogFormVisible" :props="fuyouprops3" v-model="fyform.inter_ds_area" clearable placeholder="请选择城市" @change="bankareaChange3"></el-cascader>
                <el-select v-model="fyform.inter_bank_no_s" filterable placeholder="对私结算卡联行号" style="width: 50%">
                  <el-option v-for="item in dsOptions" :key="item.bank_lhh" :label="item.name" :value="item.bank_lhh"></el-option>
                </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="商户经营范围" prop="trade_desc">
                <el-input type="textarea" v-model="fyform.trade_desc" placeholder="商户经营范围" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商户结算扣率" prop="js_set_cd">
                <el-input v-model="fyform.js_set_cd" placeholder="不开通请勿传值，扣率请传扣率代码" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="分账扣率" prop="fz_set_cd">
                <el-input v-model="fyform.fz_set_cd" placeholder="不开通请勿传值，扣率请传扣率代码" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="商户充值扣率(富友记账户)" prop="merchant_set_cd">
                <el-input v-model="fyform.merchant_set_cd" placeholder="不开通请勿传值，扣率请传扣率代码" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="用户充值扣率(富友记账户)" prop="user_set_cd">
                <el-input v-model="fyform.user_set_cd" placeholder="不开通请勿传值，扣率请传扣率代码" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="银行账户收款扣率" prop="bank_collect_set_cd">
                <el-input v-model="fyform.bank_collect_set_cd" placeholder="不开通请勿传值，扣率请传扣率代码" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="归集扣率" prop="gj_set_cd">
                <el-input v-model="fyform.gj_set_cd" placeholder="不开通请勿传值，扣率请传扣率代码" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商户结算类型" prop="settle_act_tp">
                <el-radio-group v-model="fyform.settle_act_tp" placeholder="商户结算类型" style="width: 90%">
                  <el-radio :label="1">手动结算</el-radio>
                  <el-radio :label="2">自动结算</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
            <!-- <el-col :span="8">
              <el-form-item label="门店名称" prop="county_cd">
                <el-input v-model="fyform.county_cd" style="width: 90%"></el-input>
              </el-form-item>
            </el-col> -->
          <el-row>
              <el-descriptions title="受益人信息"></el-descriptions>
            </el-row>
            <el-row>
            <el-col :span="8">
              <el-form-item label="受益人姓名" prop="bene_nm">
                <el-input v-model="fyform.bene_nm" placeholder="受益人姓名" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="受益人地址" prop="bene_addr">
                <el-input v-model="fyform.bene_addr" placeholder="受益人地址" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="受益人证件类型" prop="bene_certif_tp">
                <el-select v-model="fyform.bene_certif_tp" placeholder="受益人证件类型" style="width: 90%">
                  <el-option key="0" label="身份证" :value="0"></el-option>
                  <el-option key="1" label="护照" :value="1"></el-option>
                  <el-option key="2" label="军官证" :value="2"></el-option>
                  <el-option key="9" label="警察证" :value="9"></el-option>
                  <el-option key="B" label="通行证" value="B"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="受益人证件开始日" prop="bene_start_dt">
                <el-date-picker v-model="fyform.bene_start_dt" type="date" format="yyyy-MM-dd" value-format="yyyyMMdd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="受益人证件到期日" prop="bene_expire_dt">
                <el-date-picker v-model="fyform.bene_expire_dt" type="date" format="yyyy-MM-dd" value-format="yyyyMMdd" placeholder="选择日期" style="width: 90%"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="受益所有人认定类型" prop="bene_role">
                <el-select v-model="fyform.bene_role" placeholder="受益所有人认定类型" style="width: 90%">
                  <el-option key="0" label="股权" :value="0"></el-option>
                  <el-option key="1" label="表决权" :value="1"></el-option>
                  <el-option key="2" label="高级管理员" :value="2"></el-option>
                  <el-option key="3" label="其他" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="受益人证件号码" prop="bene_certif_no">
                <el-input v-model="fyform.bene_certif_no" placeholder="受益人证件号码" style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        
        <el-form v-if="dialogType == 'purchase'" :model="fyform" :rules="fyrules" :label-width="formLabelWidth" ref="form">
          <el-row>
            <el-col :span="12">
              <el-form-item label="营业执照照片" prop="lic_img">
                <el-upload
                  class="upload-demo"
                  action="#"
                  ref="upload"
                  accept="image/*"
                  :on-preview="handlePreview"
                  :on-remove="function(file, fileList) {return handleRemove(file, fileList,'lic_img')}"
                  multiple
                  list-type="picture-card"
                  :limit="1"
                  :file-list="fyform.lic_img"
                  :http-request="function(file) {return handleFileUpload(file,'lic_img',true)}"
                  >
                  <i class="el-icon-upload"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="法人身份证(国徽面)扫描照片" prop="certif_zm_img">
                <el-upload
                  class="upload-demo"
                  action="#"
                  ref="upload"
                  accept="image/*"
                  :on-preview="handlePreview"
                  :on-remove="function(file, fileList) {return handleRemove(file, fileList,'certif_zm_img')}"
                  multiple
                  list-type="picture-card"
                  :limit="1"
                  :file-list="fyform.certif_zm_img"
                  :http-request="function(file) {return handleFileUpload(file,'certif_zm_img',true)}"
                  >
                  <i class="el-icon-upload"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="法人身份证(人像面)扫描照片" prop="certif_fm_img">
                <el-upload
                  class="upload-demo"
                  action="#"
                  ref="upload"
                  accept="image/*"
                  :on-preview="handlePreview"
                  :on-remove="function(file, fileList) {return handleRemove(file, fileList,'certif_fm_img')}"
                  multiple
                  list-type="picture-card"
                  :limit="1"
                  :file-list="fyform.certif_fm_img"
                  :http-request="function(file) {return handleFileUpload(file,'certif_fm_img',true)}"
                  >
                  <i class="el-icon-upload"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户许可证扫描照片" prop="khxkz_img">
                <el-upload
                  class="upload-demo"
                  action="#"
                  ref="upload"
                  accept="image/*"
                  :on-preview="handlePreview"
                  :on-remove="function(file, fileList) {return handleRemove(file, fileList,'khxkz_img')}"
                  multiple
                  list-type="picture-card"
                  :limit="1"
                  :file-list="fyform.khxkz_img"
                  :http-request="function(file) {return handleFileUpload(file,'khxkz_img',true)}"
                  >
                  <i class="el-icon-upload"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="银行卡正面扫描照片" prop="yhk_img">
                <el-upload
                  class="upload-demo"
                  action="#"
                  ref="upload"
                  accept="image/*"
                  :on-preview="handlePreview"
                  :on-remove="function(file, fileList) {return handleRemove(file, fileList,'yhk_img')}"
                  multiple
                  list-type="picture-card"
                  :limit="1"
                  :file-list="fyform.yhk_img"
                  :http-request="function(file) {return handleFileUpload(file,'yhk_img',true)}"
                  >
                  <i class="el-icon-upload"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司照片" prop="company_img">
                <el-upload
                  class="upload-demo"
                  action="#"
                  ref="upload"
                  accept="image/*"
                  :on-preview="handlePreview"
                  :on-remove="function(file, fileList) {return handleRemove(file, fileList,'company_img')}"
                  multiple
                  list-type="picture-card"
                  :limit="1"
                  :file-list="fyform.company_img"
                  :http-request="function(file) {return handleFileUpload(file,'company_img',true)}"
                  >
                  <i class="el-icon-upload"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="12">
            <el-form-item label="联系人身份证(国徽面)扫描照片" prop="contact_zm_img">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                accept="image/*"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'contact_zm_img')}"
                multiple
                list-type="picture-card"
                :limit="1"
                :file-list="fyform.contact_zm_img"
                :http-request="function(file) {return handleFileUpload(file,'contact_zm_img',true)}"
                >
                <i class="el-icon-upload"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人身份证(人像面)扫描照片" prop="contact_fm_img">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                accept="image/*"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'contact_fm_img')}"
                multiple
                list-type="picture-card"
                :limit="1"
                :file-list="fyform.contact_fm_img"
                :http-request="function(file) {return handleFileUpload(file,'contact_fm_img',true)}"
                >
                <i class="el-icon-upload"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务场景描述照片" prop="busindesc_img">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                accept="image/*"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'busindesc_img')}"
                multiple
                list-type="picture-card"
                :limit="1"
                :file-list="fyform.busindesc_img"
                :http-request="function(file) {return handleFileUpload(file,'busindesc_img',true)}"
                >
                <i class="el-icon-upload"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行收款渠道截图" prop="bankrecchl_imd">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                accept="image/*"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'bankrecchl_imd')}"
                multiple
                list-type="picture-card"
                :limit="1"
                :file-list="fyform.bankrecchl_imd"
                :http-request="function(file) {return handleFileUpload(file,'bankrecchl_imd',true)}"
                >
                <i class="el-icon-upload"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码" prop="bene_certif_no">
              <el-input v-model="fyform.bene_certif_no" style="width: 90%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国徽面-扫描照片" prop="bene_zm_img">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                accept="image/*"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'bene_zm_img')}"
                multiple
                list-type="picture-card"
                :limit="1"
                :file-list="fyform.bene_zm_img"
                :http-request="function(file) {return handleFileUpload(file,'bene_zm_img',true)}"
                >
                <i class="el-icon-upload"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="人像面-扫描照片" prop="bene_fm_img">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                accept="image/*"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'bene_fm_img')}"
                multiple
                list-type="picture-card"
                :limit="1"
                :file-list="fyform.bene_fm_img"
                :http-request="function(file) {return handleFileUpload(file,'bene_fm_img',true)}"
                >
                <i class="el-icon-upload"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组织机构代码证扫描照片" prop="zzjgdmz_img">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                accept="image/*"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'zzjgdmz_img')}"
                multiple
                list-type="picture-card"
                :limit="1"
                :file-list="fyform.zzjgdmz_img"
                :http-request="function(file) {return handleFileUpload(file,'zzjgdmz_img',true)}"
                >
                <i class="el-icon-upload"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="税务登记证扫描照片" prop="tax_img">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                accept="image/*"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'tax_img')}"
                multiple
                list-type="picture-card"
                :limit="1"
                :file-list="fyform.tax_img"
                :http-request="function(file) {return handleFileUpload(file,'tax_img',true)}"
                >
                <i class="el-icon-upload"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-form>

        <div slot="footer" class="dialog-footer center">
          <el-button @click="dialogFormClose('form')">取 消</el-button>
          <el-button type="primary" @click="dialogFormSubmit('form')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  import { regionData } from "element-china-area-data";
  
  export default({
    name: "businessManage",
    data() {
      return {
        query: {
          isshenhe: '',
          storename: '',
          storeid: '',
          mobile: '',
          type_limit: 0,
        },
        queryType: '',
        isshenheOptions: [{
          value: '0',
          label: '已提交'
        }, {
          value: '1',
          label: '待签约'
        },{
          value: '2',
          label: '已审核'
        }, {
          value: '3',
          label: '已拒绝'
        }],
        storeOptions: [],
        tableData: [],
        total: 100,
        loading: false,
        title: '设置余额',
        dialogType: 'add',
        dialogFormVisible: false,
        dialogWidth: '50%',
        form: {
          storename: '',
          account_id: '',
          logo: [],
          mobile: '',
          area: '',
          faren_mobile: '',
          provinceid: '',
          cityid: '',
          areaid: '',
          withdraw_code: '',
          is_level: '',
          
          storeid: '',
          agentid: '',
          tx_code: '',
          rate: '',
          agent_rate: '',
          tgy_rate: '',
          yyzz_name: '',
          card_url: [],
          dianpuzizhi: [],
          lincese: [],

          platform: [],
          cl_date: '',
          link_url: '',
          day_money: '',
          jg_price: '',
          sale_money: '',
          address: '',
          yewu: '',
        },
        fyform: {
          storeid: '',
          ins_cd: '',
          license_type: '',
          storename: '',
          real_name: '',
          mchnt_shortname: '',
          plat_name_cn: '',
          mchnt_mcc: '',
          city_cd: '',
          county_cd: '',
          contact_addr: '',
          lic_regis_addr: '',
          regis_capital: '',
          contact_person: '',
          contact_cert_no: '',
          contact_cert_expire_dt: '',
          contact_mobile: '',
          contact_email: '',
          license_certif_tp: '',
          license_no: '',
          license_start_dt: '',
          license_expire_dt: '',
          artif_nm: '',
          artif_mobile: '',
          artif_certif_tp: '',
          certif_id: '',
          card_start_dt: '',
          certif_id_expire_dt: '',
          acnt_type: '',
          county_cd: '',
          // bene_infos: {
            bene_nm: '',
            bene_addr: '',
            bene_certif_tp: '',
            bene_expire_dt: '',
            bene_start_dt: '',
            bene_role: '',
            bene_certif_no: '',
          // },
          acnt_no_g: '',
          inter_bank_no_g: '',
          zzjgzdm_no: '',
          tax_no: '',
          acnt_no_s: '',
          inter_bank_no_s: '',
          action_type: '',
          trade_desc: '',
          js_set_cd: '',
          fz_set_cd: '',
          merchant_set_cd: '',
          user_set_cd: '',
          bank_collect_set_cd: '',
          gj_set_cd: '',
          settle_act_tp: '',

          province_code: '',
          area: '',
          inter_dg_area: '',
          inter_ds_area: '',
          lhh_province_code_g: '',
          lhh_city_code_g: '',
          lhh_province_code_s: '',
          lhh_city_code_s: '',

          lic_img: [],
          certif_zm_img: [],
          certif_fm_img: [],
          khxkz_img: [],
          yhk_img: [],
          company_img: [],
          contact_zm_img: [],
          contact_fm_img: [],
          busindesc_img: [],
          bankrecchl_imd: [],
          bene_zm_img: [],
          bene_fm_img: [],
          zzjgdmz_img: [],
          tax_img: [],
        },
        rules: {
          storename: [
            { required: true, message: '请输入门店名称', trigger: 'blur' }
          ],
          platform: [
            { required: true, message: '请选择入住平台', trigger: 'blur' }
          ],
          cl_date: [
            { required: true, message: '请选择店铺成立时间', trigger: 'blur' }
          ],
          link_url: [
            { required: true, message: '请输入店铺链接', trigger: 'blur' }
          ],
          day_money: [
            { required: true, message: '请输入日均流水', trigger: 'blur' }
          ],
          jg_price: [
            { required: true, message: '请输入产品价格范围', trigger: 'blur' }
          ],
          sale_money: [
            { required: true, message: '请输入总销售额', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '请输入商家实际经营地址', trigger: 'blur' }
          ],
          yewu: [
            { required: true, message: '请输入主营业务', trigger: 'blur' }
          ],
          account_id: [
            { required: true, message: '请输入抖音商户ID', trigger: 'blur' }
          ],
          // logo: [
          //   { required: true, message: '请上传LOGO', trigger: 'blur' }
          // ],
          mobile: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: "请输入正确的手机号码",
            }
          ],
          faren_mobile: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: "请输入正确的手机号码",
            }
          ],
          // withdraw_code: [
          //   { required: true, message: '请输入提现银行卡号', trigger: 'blur' }
          // ],
          tx_code: [
            { required: true, message: '请输入商家原提现银行卡开户行', trigger: 'blur' }
          ],
          status: [
            { required: true, message: '请选择状态', trigger: 'blur' }
          ],
          rate: [
            { required: true, message: '请输入商家抽佣比例', trigger: 'blur' }
          ],
          yyzz_name: [
            { required: true, message: '请输入营业执照名称', trigger: 'blur' }
          ],
          // agent_rate: [
          //   { required: true, message: '请输入合伙人佣金比例', trigger: 'blur' }
          // ],
          // tgy_rate: [
          //   { required: true, message: '请输入推广员佣金比例', trigger: 'blur' }
          // ],
        },
        fyrules: {
          ins_cd: [
            { required: true, message: '请输入机构号', trigger: 'blur' }
          ],
        },
        formLabelWidth: '180px',
        options: regionData,
        fuyouprops: {
          lazy: true,
          value: 'code',
          label: 'name',
          lazyLoad: this.lazyLoad 
        },
        fuyouprops2: {
          lazy: true,
          value: 'code',
          label: 'name',
          lazyLoad: this.lazyLoad2
        },
        fuyouprops3: {
          lazy: true,
          value: 'code',
          label: 'name',
          lazyLoad: this.lazyLoad3
        },
        fileList: {
          logo:[],
          card_url:[],
          dianpuzizhi:[],
          lincese:[],

          lic_img: [],
          certif_zm_img: [],
          certif_fm_img: [],
          khxkz_img: [],
          yhk_img: [],
          company_img: [],
          contact_zm_img: [],
          contact_fm_img: [],
          busindesc_img: [],
          bankrecchl_imd: [],
          bene_zm_img: [],
          bene_fm_img: [],
        },
        page: 1,
        fuyouConfig: '',
        dgOptions: [],
        dsOptions: [],
        mccOptions: [],
        platformOption: [],
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    watch: {
      '$route': {
        immediate: true,
        handler(to, from) {
          this.query.storename = ''
          this.queryType = to.query.type
          let data = {
            ...this.query,
            isshenhe: this.queryType,
            page: this.page
          }
          this.getStoreList(data)
          this.getStoreOption()
        }, 
      },  
    },
    created() {
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;
      
      this.getStorePlatform()
      this.getStoreOption()
      this.queryType = this.$route.query.type
      this.getStoreList({isshenhe:this.$route.query.type})
      this.getfuyouConfig()
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1000);
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      // 获取入驻平台
      getStorePlatform() {
        this.$api.getStorePlatform({}).then(
          res => {
            console.log('res', res)
            if(res.code == 'success'){
              this.platformOption = res.data
            }else{
              this.$message.error(res.msg)
            }
          }
        ).catch(err=>{
          this.$message.error(err.msg)
        }) 
      },
      search() {
        console.log('查询');
        let data = {
          ...this.query,
          isshenhe: this.queryType,
          page: this.page
        }
        this.getStoreList(data)
      },
      add() {
        this.title = '添加商家'
        this.dialogType= 'add'
        this.dialogWidth = '60%'
        Object.assign(this.form, {status: 1});
        this.dialogFormVisible = true
        console.log('新增');
      },
      handleEdit(id) {
        this.title = '修改商家'
        this.dialogType= 'edit'
        this.dialogWidth = '60%'
        this.getStoreInfo(id)
        this.dialogFormVisible = true
        console.log('编辑');
      },
      // 修改信息登记
      mchntUpd(id) {
        this.title = '修改信息登记'
        this.dialogType= 'mchntAdd'
        this.getfuyouStoreInfo(id, 'mchntAdd')
        this.dialogWidth = '70%'
        this.dialogFormVisible = true
        console.log('修改信息登记');
      },
      // 进件
      purchaseGood(id) {
        this.title = '商家进件'
        this.dialogType= 'purchase'
        this.getfuyouStoreInfo(id,'purchase')
        this.dialogFormVisible = true
        console.log('商家进件');
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val
        this.search()
      },
      // 弹窗关闭逻辑
      dialogFormClose(formName) {
        this.$refs[formName].resetFields();           // 移除表单验证
        Object.keys(this.form).forEach(key => {
          if (['logo','card_url','dianpuzizhi','lincese','platform'].includes(key)) {
            this.form[key] = [];
          } else {
            this.form[key] = '';
          }
        });                                           // 清除数据缓存
        Object.keys(this.fyform).forEach(key => {
          if (['lic_img','certif_zm_img','certif_fm_img','khxkz_img','yhk_img','company_img','contact_zm_img','contact_fm_img','busindesc_img','bankrecchl_imd','bene_zm_img','bene_fm_img','zzjgdmz_img','tax_img'].includes(key)) {
            this.fyform[key] = [];
          } else {
            this.fyform[key] = '';
          }
        });                                           // 清除数据缓存
        this.fileList.logo.splice(0, this.fileList.logo.length)     // 移除上传组件图片数组
        this.fileList.card_url.splice(0, this.fileList.card_url.length) // 移除上传组件图片数组
        this.fileList.dianpuzizhi.splice(0, this.fileList.dianpuzizhi.length) // 移除上传组件图片数组
        this.fileList.lincese.splice(0, this.fileList.lincese.length) // 移除上传组件图片数组
        this.dialogWidth = '50%'
        this.dialogFormVisible = false                // 关闭弹窗
      },
      // 处理图片移除操作
      handleRemove(file, fileList, type) {
        console.log('file, fileList',file, fileList, type);
        this.form[type] = this.form[type].filter(obj => {
          if (obj.uid) {
            return obj.uid !== file.uid
          } else if (obj.media_id) {
            return obj.media_id !== file.media_id
          }
        });
        // this.form[type] = ''
        // this.fileList[type] = []
      },
      // 处理图片预览操作
      handlePreview(file) {
        console.log(file);
      },
      // 处理文件、图片上传操作
      handleFileUpload(file,type,isfuyou) {
        console.log('file, type', file, type);
        // 使用FormData传参数和文件
        const form = new FormData()
        form.append("file", file.file)
        this.$api.upImg(form).then(res => {
          if(res.code == 'success'){
            if (isfuyou) {
              this.fyform[type]?'':this.fyform[type]=[]
              this.fyform[type][0]={url:res.data.show_url}
              const suffix = file.file.type.substring(file.file.type.indexOf('/')+1, file.file.type.length)
              let data = {
                storeid: this.fyform.storeid,
                suffix: '.'+suffix
              }
              // 图片上传签名参数
              this.$api.getsignimg(data).then(res => {
                if(res.code == 'success'){
                  // 使用FormData传参数和文件
                  form.append('ins_cd', res.data.ins_cd)
                  form.append('mchnt_cd', res.data.mchnt_cd)
                  form.append('file_name', res.data.file_name)
                  form.append('sign', res.data.sign)
                  form.append('trace_no', res.data.trace_no)
                  // 富友上传图片
                  this.$api.upImg2(form).then(res => {
                    if(res.ret_code == '0000'){
                      this.$message.success('上传成功'||res.ret_msg)
                      this.fyform[type][0]['media_id'] = res.media_id
                      console.log(type, this.fyform[type])
                    }else{
                      this.$message.error(res.msg)
                    }
                  })
                }else{
                  this.$message.error(res.msg)
                }
              })
            } else {
              console.log('this.form[type]', this.form[type]);
              this.form[type].push({uid: file.file.uid, url:res.data.show_url})
            }
          }else{
            this.$message.error(res.msg)
          }
          this.loading = false
        }).catch((e) => {
          this.$message.error(e.message);
          this.$refs.upload.clearFiles();
        })
      },
      // 深市县三级联动
      areaChange(e) {
        console.log('e', e, this.options)
        if (this.dialogType == 'mchntAdd' || this.dialogType == 'mchntUpd') {
          Object.assign(this.fyform, {province_code: e[0], city_cd: e[1], county_cd: e[2]})
        } else {
          Object.assign(this.form, {provinceid: e[0], cityid: e[1], areaid: e[2]})
        }
      },
      bankareaChange2(e) {
        console.log('e', e);
        Object.assign(this.fyform, {lhh_province_code_g: e[0], lhh_city_code_g: e[1]})
        this.getbanklhh(e[1], 'inter_bank_no_g')
      },
      bankareaChange3(e) {
        console.log('e', e)
        Object.assign(this.fyform, {lhh_city_code_s: e[0], lhh_province_code_s: e[1]})
        this.getbanklhh(e[1], 'inter_bank_no_s')
      },
      // 表单提交
      dialogFormSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            if (this.dialogType === 'add') {
              this.form.agent_rate==''?this.form.agent_rate==0:''
              this.form.tgy_rate==''?this.form.tgy_rate==0:''
              let logo = this.form.logo[0]?.url
              let card_url = this.form.card_url?.map(obj => obj.url)
              let dianpuzizhi = this.form.dianpuzizhi?.map(obj => obj.url)
              let lincese = this.form.lincese?.map(obj => obj.url)
              let data = {
                ...this.form,
                logo,
                card_url,
                dianpuzizhi,
                lincese
              }
              this.$api.addStore(data).then(
                res => {
                  if(res.code == 'success'){
                    this.$message.success(res.msg)
                    this.search()
                    this.dialogFormVisible = false
                  }else{
                    this.$message.error(res.msg)
                  }
                  this.loading = false
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
            }
            else if (this.dialogType === 'edit') {
              let logo = this.form.logo[0]?.url
              let card_url = this.form.card_url?.map(obj => obj.url)
              let dianpuzizhi = this.form.dianpuzizhi?.map(obj => obj.url)
              let lincese = this.form.lincese?.map(obj => obj.url)
              let data = {
                ...this.form,
                logo,
                card_url,
                dianpuzizhi,
                lincese
              }
              this.$api.editStore(data).then(
                res => {
                  if(res.code == 'success'){
                    this.$message.success(res.msg)
                    this.search()
                    this.dialogFormVisible = false
                  }else{
                    this.$message.error(res.msg)
                  }
                  this.loading = false
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
            }
            else if (this.dialogType === 'bindAgent') {
              this.$api.setAgent({...this.form}).then(
                res => {
                  if(res.code == 'success'){
                    this.$message.success(res.msg)
                    this.search()
                  }else{
                    this.$message.error(res.msg)
                  }
                  this.loading = false
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
              this.dialogFormVisible = false
            }
            else if (this.dialogType === 'mchntAdd'||this.dialogType === 'mchntUpd') {
              this.fyform.action_type = this.dialogType
              this.fyform.mchnt_mcc = '7011'
              this.fyform.bene_infos = {
                bene_nm: this.fyform.bene_nm,
                bene_addr: this.fyform.bene_addr,
                bene_certif_tp: this.fyform.bene_certif_tp,
                bene_expire_dt: this.fyform.bene_expire_dt,
                bene_start_dt: this.fyform.bene_start_dt,
                bene_role: this.fyform.bene_role,
                bene_certif_no: this.fyform.bene_certif_no,
              }
              this.fyform.inter_bank_no_g = '102584001030'
              this.fyform.inter_bank_no_s = '102584001030'
              console.log('this.fyform', this.fyform)
              this.$api.mchntAdd(this.fyform).then(
                res => {
                  if(res.code == 'success'){
                    this.$message.success(res.msg)
                    this.search()
                  }else{
                    this.$message.error(res.msg)
                  }
                  this.loading = false
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
              // this.dialogFormVisible = false
            }
            else if (this.dialogType === 'purchase') {
              const {storeid, lic_img, certif_zm_img, certif_fm_img, khxkz_img, yhk_img, company_img, contact_zm_img, contact_fm_img, busindesc_img, bankrecchl_imd, bene_zm_img, bene_fm_img, zzjgdmz_img, tax_img} = this.fyform
              let data = {
                storeid,
                lic_img: lic_img?.[0]?Object.assign({}, {media_id:lic_img[0].media_id, url:lic_img[0].url}):{media_id:'',url:''},
                certif_zm_img: certif_zm_img?.[0]?Object.assign({}, {media_id:certif_zm_img[0].media_id, url:certif_zm_img[0].url}):{media_id:'',url:''},
                certif_fm_img: certif_fm_img?.[0]?Object.assign({}, {media_id:certif_fm_img[0].media_id, url:certif_fm_img[0].url}):{media_id:'',url:''},
                khxkz_img: khxkz_img?.[0]?Object.assign({}, {media_id:khxkz_img[0].media_id, url:khxkz_img[0].url}):{media_id:'',url:''},
                yhk_img: yhk_img?.[0]?Object.assign({}, {media_id:yhk_img[0].media_id, url:yhk_img[0].url}):{media_id:'',url:''},
                company_img: company_img?.[0]?Object.assign({}, {media_id:company_img[0].media_id, url:company_img[0].url}):{media_id:'',url:''},
                contact_zm_img: contact_zm_img?.[0]?Object.assign({}, {media_id:contact_zm_img[0].media_id, url:contact_zm_img[0].url}):{media_id:'',url:''},
                contact_fm_img: contact_fm_img?.[0]?Object.assign({}, {media_id:contact_fm_img[0].media_id, url:contact_fm_img[0].url}):{media_id:'',url:''},
                busindesc_img: busindesc_img?.[0]?Object.assign({}, {media_id:busindesc_img[0].media_id, url:busindesc_img[0].url}):{media_id:'',url:''},
                bankrecchl_imd: bankrecchl_imd?.[0]?Object.assign({}, {media_id:bankrecchl_imd[0].media_id, url:bankrecchl_imd[0].url}):{media_id:'',url:''},
                bene_img: {
                  bene_zm_img: bene_zm_img?.[0]?Object.assign({}, {media_id:bene_zm_img[0].media_id, url:bene_zm_img[0].url}):{media_id:'',url:''},
                  bene_fm_img: bene_fm_img?.[0]?Object.assign({}, {media_id:bene_fm_img[0].media_id, url:bene_fm_img[0].url}):{media_id:'',url:''},
                },
                zzjgdmz_img: zzjgdmz_img?.[0]?Object.assign({}, {media_id:zzjgdmz_img[0].media_id, url:zzjgdmz_img[0].url}):{media_id:'',url:''},
                tax_img: tax_img?.[0]?Object.assign({}, {media_id:tax_img[0].media_id, url:tax_img[0].url}):{media_id:'',url:''},
              }
              console.log('data', data);
              this.$api.fuguanjai(data).then(
                res => {
                  if(res.code == 'success'){
                    this.$message.success('操作成功'||res.msg)
                    this.search()
                  }else{
                    this.$message.error(res.msg)
                  }
                  this.loading = false
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
              this.dialogFormVisible = false
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 商家列表
      getStoreOption() {
        this.loading = true
        // 0：有分页，1：无分页数据
        this.$api.getStoreList({isshenhe:this.queryType,type_limit:1}).then(
          res => {
            if(res.code == 'success'){
              this.storeOptions = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      // 获取商家列表
      getStoreList(data) {
        this.loading = true
        // 合伙人参数
        this.$api.getStoreList({...data}).then(
          res => {
            if(res.code == 'success'){
              this.tableData = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      // 获取商家详情
      getStoreInfo(storeid) {
        this.$api.getStoreInfo({storeid}).then(
          res => {
            if(res.code == 'success'){
              this.form = Object.assign({...this.form, storeid:res.data.id}, res.data)
              this.form.area = [String(res.data.provinceid),String(res.data.cityid),String(res.data.areaid)]
              this.fileList.logo.push({ url: res.data.logo })
              res.data.card_url.length>0?(res.data.card_url.map(item => {
                item.url=item.path
                this.fileList.card_url.push(item)
              })):''
              res.data.dianpuzizhi.length>0?(res.data.dianpuzizhi.map(item => {
                item.url=item.path
                this.fileList.dianpuzizhi.push({url:item.path})
              })):''
              res.data.lincese.length>0?(res.data.lincese.map(item => {
                item.url=item.path
                this.fileList.lincese.push({url:item.path})
              })):''
              this.form.logo = this.fileList.logo
              this.form.card_url = this.fileList.card_url
              this.form.dianpuzizhi = this.fileList.dianpuzizhi
              this.form.lincese = this.fileList.lincese

              if(!(typeof res.data.platform === "object" && Array.isArray(res.data.platform))) {
                res.data.platform?(this.form.platform=[res.data.platform]):(this.form.platform = [])
                console.log('this.form.platform', this.form.platform)
              }
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      // 获取富友商家详情
      getfuyouStoreInfo(storeid, type) {
        this.$api.getfuyouStoreInfo({storeid}).then(
          res => {
            if(res.code == 'success'){
              this.title = type=='purchase'?this.title:res.data.fuguanjia.ins_cd?'修改信息登记':'新增信息登记'
              this.dialogType = type=='purchase'?this.dialogType:res.data.fuguanjia.ins_cd?'mchntUpd':'mchntAdd'

              this.fyform = Object.assign({...this.fyform, storeid:res.data.id}, res.data.fuguanjia)
              this.fyform = Object.assign({...this.fyform}, res.data.fuguanjia.bene_infos)
              this.fyform.mchnt_cd = res.data.fuguanjia.fy_mchnt_cd
              this.fyform.storename = res.data.storename
              this.fyform.area = [res.data.fuguanjia.province_code,res.data.fuguanjia.city_cd,res.data.fuguanjia.county_cd]
              this.fyform.inter_dg_area = [res.data.fuguanjia.lhh_province_code_g, res.data.fuguanjia.lhh_city_code_g]
              this.fyform.inter_ds_area = [res.data.fuguanjia.lhh_province_code_s, res.data.fuguanjia.lhh_city_code_s]

              // this.fyform.contact_cert_expire_dt?'':this.fyform.contact_cert_expire_dt=''
              // this.fyform.license_start_dt?'':this.fyform.license_start_dt=''
              // this.fyform.license_expire_dt?'':this.fyform.license_expire_dt=''
              // this.fyform.card_start_dt?'':this.fyform.card_start_dt=''
              // this.fyform.certif_id_expire_dt?'':this.fyform.certif_id_expire_dt=''
              // this.fyform.bene_start_dt?'':this.fyform.bene_start_dt=''
              // this.fyform.bene_expire_dt?'':this.fyform.bene_expire_dt=''

              this.fyform.lic_img = [res.data.fuguanjia.lic_img]
              this.fyform.certif_zm_img = [res.data.fuguanjia.certif_zm_img]
              this.fyform.certif_fm_img = [res.data.fuguanjia.certif_fm_img]
              this.fyform.khxkz_img = [res.data.fuguanjia.khxkz_img]
              this.fyform.yhk_img = [res.data.fuguanjia.yhk_img]
              this.fyform.company_img = [res.data.fuguanjia.company_img]
              this.fyform.contact_zm_img = [res.data.fuguanjia.contact_zm_img]
              this.fyform.contact_fm_img = [res.data.fuguanjia.contact_fm_img]
              this.fyform.busindesc_img = [res.data.fuguanjia.busindesc_img]
              this.fyform.bankrecchl_imd = [res.data.fuguanjia.bankrecchl_imd]
              this.fyform.bene_zm_img = [res.data.fuguanjia.bene_img?.bene_zm_img?res.data.fuguanjia.bene_img.bene_zm_img:null]
              this.fyform.bene_fm_img = [res.data.fuguanjia.bene_img?.bene_fm_img?res.data.fuguanjia.bene_img.bene_fm_img:null]
              this.fyform.zzjgdmz_img = [res.data.fuguanjia.zzjgdmz_img]
              this.fyform.tax_img = [res.data.fuguanjia.tax_img]
              
              this.getmcc(storeid)
              res.data.fuguanjia.lhh_city_code_g?this.getbanklhh(res.data.fuguanjia.lhh_city_code_g, 'inter_bank_no_g'):''
              res.data.fuguanjia.lhh_city_code_s?this.getbanklhh(res.data.fuguanjia.lhh_city_code_s, 'inter_bank_no_s'):''
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          console.log('err', err);
          // this.$message.error(err)
          this.loading = false
        }) 
      },
      // 富友获取配置参数
      getfuyouConfig() {
        this.$api.getfuyouConfig({}).then(
          res => {
            console.log('res', res)
            if(res.code == 'success'){
              res.data.province.map(item => {
                item.name = item.name.split(" ").join("")
              })
              this.fuyouConfig = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        }) 
      },
      // 富友获取城市地区
      getfuyouArea() {
        this.$api.getfuyouArea({parent_code:'440'}).then(
          res => {
            console.log('富友获取城市地区', res)
            if(res.code == 'success'){

            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        }) 
      },
      // 富友获取银行联行号
      getbanklhh(city_code, type) {
        console.log('city_code, type', city_code, type);
        this.$api.getbanklhh({city_code}).then(
          res => {
            console.log('res', res)
            if(res.code == 'success'){
              if (type == 'inter_bank_no_g') {
                this.dgOptions = res.data
              } else if (type == 'inter_bank_no_s') {
                this.dsOptions = res.data
              }
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        }) 
      },
      // 富友提交审核接口
      mchntAudit() {
        this.$api.mchntAudit({storeid: 11}).then(
          res => {
            console.log('res', res)
            if(res.code == 'success'){

            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        }) 
      },
      // 富友开通状态查询接口
      mchntStatusQuery(storeid) {
        this.$api.mchntStatusQuery({storeid}).then(
          res => {
            console.log('res', res)
            if(res.code == 'success'){
              this.$message.success('查询成功'||res.msg)
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        }) 
      },
      // 异步省市区获取
      async lazyLoad(node, resolve) {
        const { level } = node;
        switch (level) {
          case 0: //一级目录
            let result = this.fuyouConfig.province
            resolve(result)
          break
          case 1: //一级目录
          this.$api.getfuyouArea({parent_code:node.data.code}).then(
            res => {
              let result = res.data.map(item => {
                item.name = item.name.split(" ").join("")
                item.leaf = false
                return item
              })
              resolve(result)
            }
          )
          break
          case 2: // 二级目录
          this.$api.getfuyouArea({parent_code:node.data.code}).then(
            res => {
              let result = res.data.map(item => {
                item.name = item.name.split(" ").join("")
                item.leaf = true
                return item
              })
              resolve(result)
            }
          )
        };
      },
      // 异步省市区获取
      async lazyLoad2(node, resolve) {
        const { level } = node;
        switch (level) {
          case 0: //一级目录
            let result = this.fuyouConfig.province
            resolve(result)
          break
          case 1: //一级目录
          this.$api.getfuyouArea({parent_code:node.data.code}).then(
            res => {
              let result = res.data.map(item => {
                item.name = item.name.split(" ").join("")
                item.leaf = true
                return item
              })
              resolve(result)
            }
          )
        };
      },
      // 异步省市区获取
      async lazyLoad3(node, resolve) {
        const { level } = node;
        switch (level) {
          case 0: //一级目录
            let result = this.fuyouConfig.province
            resolve(result)
          break
          case 1: //一级目录
          this.$api.getfuyouArea({parent_code:node.data.code}).then(
            res => {
              let result = res.data.map(item => {
                item.name = item.name.split(" ").join("")
                item.leaf = true
                return item
              })
              resolve(result)
            }
          )
        };
      },
      // 富友商户类别码
      getmcc(storeid) {
        this.$api.getmcc({storeid}).then(
          res => {
            console.log('res', res)
            if(res.code == 'success'){
              this.mccOptions = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        }) 
      },
      // 绑定业务员(设置代理)
      bindAgent(storeid) {
        this.title = '绑定业务员'
        this.dialogType= 'bindAgent'
        Object.assign(this.form, {agentid:'',storeid})
        
        this.dialogFormVisible = true
      },
      // 解绑业务员(取消绑定代理)
      unbindSalesman(storeid) {
        this.$api.cancelAgent({storeid}).then(
          res => {
            if(res.code == 'success'){
              this.$message.success(res.msg)
              this.search()
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../assets/less/easyElement.less";
  .el-tag{
    white-space: normal;
    height:auto;
  }
  </style>
  